import React from 'react';
import AngeloPointing from 'images/avatars/avatar_angelo-dini_pointing.svg';
import AngeloPresenting from 'images/avatars/avatar_angelo-dini_presenting.svg';

import Head from 'components/layout/head';
import Layout from 'components/layout';
import { Row, Column } from 'components/grid';
import Spacer from 'components/spacer/spacer';
import Experience from 'components/about/experience';
import Testimonials from 'components/about/testimonials';
import Chart from 'components/about/chart';

const About: React.FC = () => (
  <Layout>
    <Head pageTitle="About" />

    <Row tag="header" id="section-start" className="main__header">
      <Column>
        <h1 className="text-center">Explore my profile</h1>
        <p className="lead text-success text-center">
          I live a humble life in Bern – Switzerland and work for Divio
        </p>
      </Column>
    </Row>

    <Experience />
    <Spacer size={2} id="section-personal" />

    <Row tag="section">
      <Column tag="section" size={6}>
        <h2 className="h3 text-success mb-0">Personal Life</h2>
        <h3 className="h1 mt-0">Getting to know me</h3>
        <p>
          I grew up in a little valley in the South of Switzerland—home of the
          <br />
          Matterhorn—with my grandparents. They unwillingly fostered my interest
          in technology by letting me break apart VCRs, TVs, computers and more.
          Ever since then, I wanted to become an IT-Specialist.
        </p>
        <p className="text-light">
          This passion drove me to start a new life in Vancouver, master english
          and work in various IT related fields. Once back in Switzerland, my
          career as a web developer began.
        </p>
      </Column>
      <Column tag="section" size={5}>
        <div className="avatar--right">
          <AngeloPointing />
        </div>
      </Column>
    </Row>

    <Spacer size={2} />
    <Testimonials />
    <Spacer size={2} id="section-professional" />

    <Row tag="section">
      <Column tag="section" size={5}>
        <div className="avatar--left">
          <AngeloPresenting />
        </div>
      </Column>
      <Column tag="section" size={6}>
        <h2 className="h3 text-success mb-0">Professional Life</h2>
        <h3 className="h1 mt-0">Building my tech stack</h3>
        <p>
          My apprenticeship, the experience abroad and several side-jobs enabled
          me to have an in-depth understanding of how a business works. I’m
          known to work very structured and detail-oriented towards any given
          goal. My definition of done includes tests.
        </p>
        <p className="text-light">
          During my professional career I had the opportunity to work with PHP,
          .NET, Python and various front-end technologies. These days I consider
          myself as a full-stack developer but my roots lie in the front-end.
        </p>
      </Column>
    </Row>

    <Spacer size={2} />

    <Chart />
  </Layout>
);

export default About;
