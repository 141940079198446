import React from 'react';
import Slider from 'react-slick';

import './testimonials.scss';

import apgBanner from './assets/logo-apgsga.png';
import divioAppBanner from './assets/logo-app.png';
// import divioCliBanner from './assets/logo-cli.png';
import divioCpBanner from './assets/logo-cp.png';
import divioBanner from './assets/logo-divio.png';
import djangocmsBanner from './assets/logo-djangocms.png';
import fidelityBanner from './assets/logo-fidelity.png';
import lwlBanner from './assets/logo-lwl.png';
// import migrosBanner from './assets/logo-migros.png';
import postBanner from './assets/logo-post.png';
import preziBanner from './assets/logo-prezi.png';
import sbbBanner from './assets/logo-sbb.png';
// import swisscomBanner from './assets/logo-swisscom.png';
import zkbBanner from './assets/logo-zkb.png';
// TODO: add salt

const Testimonial = () => {
  const testimonials = [
    {
      image: divioBanner,
      title: 'www.divio.com',
      tech: 'Built with React & Gatsby.',
      description: 'Main marketing instrument for Divio.',
      url: 'https://www.divio.com/',
    },
    {
      image: divioCpBanner,
      title: 'Divio Control Panel',
      tech: 'Built with Django & React.',
      description: 'Deploy your projects on Divio Cloud.',
      url: 'https://control.divio.com/',
    },
    {
      image: preziBanner,
      title: 'Prezi',
      tech: 'Built with Python/Django.',
      description: 'django CMS consulting and support.',
      url: 'https://www.prezi.com/',
    },
    // {
    //   image: divioCliBanner,
    //   title: 'Divio CLI',
    //   tech: 'Built with Node.',
    //   description: 'Command line interface for Divio.',
    //   url: 'https://github.com/divio/divio-cli/',
    // },
    {
      image: sbbBanner,
      title: 'SBB CFF FFS',
      tech: 'Built with .NET.',
      description: 'Marketing and landing pages.',
      url: 'https://www.sbb.ch/',
    },
    {
      image: zkbBanner,
      title: 'Zürcher Kantonalbank',
      tech: 'Built with Jinja and Bootstrap.',
      description: 'Consulting, architecture and dev.',
      url: 'https://www.zkb.ch/',
    },
    {
      image: djangocmsBanner,
      title: 'django CMS',
      tech: 'Build with Python/Django.',
      description: 'Popular Django-based CMS.',
      url: 'https://github.com/divio/divio-cli/',
    },
    {
      image: lwlBanner,
      title: 'LWL',
      tech: 'Built with Python/Django.',
      description: 'Hosted on Divio Cloud.',
      url: 'https://www.lwl.org/',
    },
    {
      image: apgBanner,
      title: 'APG|SGA',
      tech: 'Built with Python/Django.',
      description: 'Multienvironment website setup.',
      url: 'https://www.apgsga.ch/',
    },
    {
      image: postBanner,
      title: 'PostActivity',
      tech: 'Built with Python/Django.',
      description: 'Team activities for the staff.',
      url: 'https://www.postactivity.ch/',
    },
    // {
    //   image: swisscomBanner,
    //   title: 'Swisscom',
    //   tech: 'Built with Python/Django.',
    //   description: 'Educational tools for schools.',
    //   url: 'https://www.swisscom.ch/',
    // },
    // {
    //   image: migrosBanner,
    //   title: 'Migros Magazine',
    //   tech: 'Built with Python/Django.',
    //   description: 'Recipes and news articles.',
    //   url: 'https://www.migrosmagazine.ch/',
    // },
    {
      image: fidelityBanner,
      title: 'Fidelity',
      tech: 'Built with Python/Django.',
      description: 'Hosted on Divio Cloud.',
      url: 'https://www.fidelityinternational.com/',
    },
    {
      image: divioAppBanner,
      title: 'Divio App',
      tech: 'Built with Electron.',
      description: 'Setup projects locally.',
      url: 'https://www.divio.com/developers/#app',
    },
  ];

  // https://react-slick.neostack.com/docs/example/multiple-items
  const sliderSettings = {
    centerMode: true,
    infinite: true,
    slidesToShow: 5,
    variableWidth: true,

    // optional
    // lazyLoad: true,
    swipeToSlide: true,
    arrows: false,
    dots: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="testimonials" id="section-testimonials">
      <h2 className="h1 text-center">Projects I’ve worked on</h2>
      <h2 className="h3 text-success text-center">
        A selection of projects I’ve been substantially involved in
      </h2>

      <div className="testimonials__slider">
        <Slider {...sliderSettings}>
          {testimonials.map(testimonial => (
            <a
              href={testimonial.url}
              key={testimonial.title}
              className="testimonial__item"
              target="_blank"
              rel="noreferrer">
              <img src={testimonial.image} alt={testimonial.title} />
              <h3 className="mb-0 title">{testimonial.title}</h3>
              <p className="mt-0 description">
                {testimonial.tech}
                <br />
                {testimonial.description}
              </p>
            </a>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Testimonial;
