import { FONT_BASE } from 'constants/theme';

import React from 'react';

import './chart.scss';

const Chart = () => {
  const items = [
    '-:-',
    'SEO',
    'Accessibility',
    'HTML, Email',
    'CSS, Sass',
    'ES6, Typescript',
    'JavaScript',
    'React, Redux, Sagas',
    'GraphQL, Rest',
    'Node, Webpack',
    'Electron',
    'Jasmine, Jest',
    'Express, Gatsby',
    'Django, django CMS',
    'Python',
    'Docker, Kubernetes',
    'Cloud, Divio',
    'Design',
    'Adobe',
    'Drawing',
    'Notion',
    'C#',
  ];
  const size = [
    3, 5, 7, 9, 11, 14, 17, 14, 12, 10, 8, 8, 9, 11, 13, 11, 8, 9, 8, 6, 4, 3,
  ];
  const active = [6, 14, 17];
  const data = items.map((item, index) => {
    return {
      text: item,
      size: size[index],
      highlight: active.includes(index) ? true : false,
    };
  });

  return (
    <div className="chart" id="section-techtree">
      <h2 className="h1 text-center">Tech Tree</h2>
      <h2 className="h3 text-success text-center">
        I’m specialised in web development
      </h2>
      <div className="chart__wrapper">
        <div className="chart__container">
          {data.map(item => (
            <div
              className={
                `chart__bar` + (item.highlight ? ' chart__bar--highlight' : '')
              }
              key={item.text}
              style={{ height: FONT_BASE * item.size + 'px' }}>
              <span>{item.text}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Chart;
