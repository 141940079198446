import { BULL } from 'constants/theme';

import React from 'react';
import Badge from 'images/icons/icon-badge.svg';

import { Row, Column } from 'components/grid';
import Box from 'components/about/box';

import './experience.scss';

interface Props {
  logo: string;
  logoName: string;
  titleLine: string;
  titleDate?: string;
  subLine?: string;
  description: string;
}

import divioLogo from './logos/logo-divio.png';
import hsrLogo from './logos/logo-hsr.png';
import unicLogo from './logos/logo-unic.png';
import maxoLogo from './logos/logo-maxomedia.png';
import operaLogo from './logos/logo-opra.png';
import fhnwLogo from './logos/logo-fhnw.png';
import zbLogo from './logos/logo-zb.png';
import efLogo from './logos/logo-ef.png';
import vispLogo from './logos/logo-visp.png';

const Experience = () => {
  const work_items = [
    {
      logo: divioLogo,
      logoName: 'Divio AG',
      titleLine: 'Divio',
      titleDate: '2010 – now',
      description: 'Full-Stack Developer',
    },
    {
      logo: hsrLogo,
      logoName: 'HSR Rapperswil',
      titleLine: 'HSR Rapperswil',
      titleDate: '2015 – now',
      description: 'CAS Frontend Instructor',
    },
    {
      logo: unicLogo,
      logoName: 'UNIC',
      titleLine: 'UNIC',
      titleDate: '2010',
      description: 'Frontend Developer',
    },
    {
      logo: maxoLogo,
      logoName: 'Maxomedia',
      titleLine: 'Maxomedia',
      titleDate: '2007 – 2010',
      description: 'Frontend Developer',
    },
    {
      logo: operaLogo,
      logoName: 'OPRA',
      titleLine: 'OPRA',
      titleDate: '2002 – 2006',
      description: 'Mediamatician Apprenticeship',
    },
  ];

  const education_items = [
    {
      logo: fhnwLogo,
      logoName:
        'University of Applied Sciences and Arts Northwestern Switzerland',
      titleLine: 'BSc Computer Science',
      subLine: '2020 – 2022',
      description: 'FHNW, Brugg AG, Switzerland',
    },
    {
      logo: zbLogo,
      logoName: 'Zentrum Bildung',
      titleLine: 'BSc Enrollment Course',
      subLine: '2019 – 2020',
      description: 'BWZ, Brugg AG, Switzerland',
    },
    {
      logo: efLogo,
      logoName: 'English First',
      titleLine: 'EF Master English',
      subLine: '2006 – 2007',
      description: 'EF, Vancouver, Canada',
    },
    {
      logo: vispLogo,
      logoName: 'Trade School Visp',
      titleLine: 'Mediamatician',
      subLine: '2002 – 2006',
      description: '',
    },
  ];

  const achievement_items = [
    {
      id: 'ach_1',
      el: (
        <p>
          Checkout my ongoing{' '}
          <a
            href="https://www.goodreads.com/user_challenges/40538599"
            target="_blank"
            rel="noreferrer">
            reading challenge
          </a>
        </p>
      ),
    },
    {
      id: 'ach_2',
      el: (
        <p>
          Core Developer of <span>django-CMS</span> since 2014 and member of the
          Association
        </p>
      ),
    },
    {
      id: 'ach_3',
      el: (
        <p>
          Organised <span>Frontend Conference</span> for 6 years and{' '}
          <span>DjangoCon</span> in 2012
        </p>
      ),
    },
    {
      id: 'ach_4',
      el: (
        <p>
          <span>Teaching</span> Frontend Development since 2010
        </p>
      ),
    },
  ];

  const Media = (props: Props) => (
    <div key={props.logoName} className="media">
      <img
        src={props.logo}
        className="media-image"
        alt={props.logoName}
        width={52}
        height={52}
      />
      <div className="media-body">
        <h3>
          <strong>{props.titleLine}</strong>
          {props.titleDate ? ` ${BULL} ${props.titleDate}` : null}
        </h3>
        {props.subLine ? `${props.subLine}` : null}
        <p>{props.description}</p>
      </div>
    </div>
  );

  const Achievement: React.FC = ({ children }) => (
    <div className="media media-badge">
      <Badge />
      {children}
    </div>
  );

  return (
    <Row tag="section">
      <Column size={4}>
        <Box>
          <h2 className="heading">Work Experience</h2>
          {work_items.map(item => (
            <Media key={item.logoName} {...item} />
          ))}
        </Box>
      </Column>
      <Column size={3}>
        <Box>
          <h2 className="heading">Achievements</h2>
          <div className="achievements">
            {achievement_items.map(item => (
              <Achievement key={item.id}>{item.el}</Achievement>
            ))}
          </div>
        </Box>
      </Column>
      <Column size={4}>
        <Box>
          <h2 className="heading">Education</h2>
          {education_items.map(item => (
            <Media key={item.logoName} {...item} />
          ))}
        </Box>
      </Column>
    </Row>
  );
};

export default Experience;
